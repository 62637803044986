import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import OverviewHeader from '../../layout-components/MyHeader/myheader';
import { connect } from 'react-redux';
import { Sidebar, Header, Footer} from '../../layout-components';
import background2 from './nasa-Q1p7bh3SHj8-unsplash.jpg';
import Tree from './tree.jpg';
import { Layout, Menu, Breadcrumb } from 'antd';
//////////////////try tabs
import { StickyContainer, Sticky } from 'react-sticky';
import { Tabs, Radio } from 'antd';
import avanLogo from '../../assets/images/myImage/AVAN.png';
const { TabPane } = Tabs;
//const { AntdHeader, Content, Footer } = Layout;



const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground
  } = props;

  return (
    <>

      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        

        <div>
          <Sidebar />
        </div>
        <div className="app-main">

          <Header />
          <div className="app-content" >
            <div className="app-content--inner" style={{ marginTop: '65px', backgroundImage: "url(" + background2 + ")", backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
              <div className="app-content--inner__wrapper" >{children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);
