import React, { lazy, Suspense } from 'react';
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Hidden from '@material-ui/core/Hidden';
import { ClimbingBoxLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import {
  LeftSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';
//////////////////try tabs
import avanLogo from './assets/images/myImage/AVANIOT_BMS_IOT_SOLUTIONS_tm.png';
import { Layout, Menu, Breadcrumb, Row, Col, Button } from 'antd';
import './for_layout_header.css';
const { Header } = Layout;
const languageMap = {
  en: { label: 'English', dir: 'ltr', active: true }, //ltr: left to right
  tc: { label: '繁體中文', dir: 'ltr', active: false }, //rtl: right to left,  有d人向左/向右睇起
  sc: { label: '簡體中文', dir: 'ltr', active: false }
};
/////////////////////////////////////////////
const PageLoginBasic = lazy(() => import('./myPages/Login/login_page')); //remember this
const PageHome = lazy(() => import('./myPages/Home/home_page2'));
const PageWaterLeakage = lazy(() =>
  import('./myPages/SmartToilet/WaterLeakage/WaterLeakage_app')
);
const PageIAQ = lazy(() => import('./myPages/SmartToilet/IAQ/IAQ_app'));
const PageSmoke = lazy(() => import('./myPages/SmartToilet/Smoke/smoke_app'));
const PageCubicle = lazy(() =>
  import('./myPages/SmartToilet/Cubicle/Cubicle_app')
);
const PageSoap = lazy(() => import('./myPages/SmartToilet/Soap/Soap_app'));
const PageHandPaper = lazy(() =>
  import('./myPages/SmartToilet/HandPaper/handpaper_app')
);

const PageQueue = lazy(() => import('./myPages/SmartToilet/Queue/Queue_app'));
const PagePeopleCount = lazy(() =>
  import('./myPages/SmartToilet/PeopleCount/PeopleCount_app')
);
const PageDoor = lazy(() => import('./myPages/SmartToilet/Door/Door_app'));
const PageTissuePaper = lazy(() =>
  import('./myPages/SmartToilet/TissuePaper/TissuePaper_app')
);
const PageUrinal = lazy(() =>
  import('./myPages/SmartToilet/Urinal/Urinal_app')
);
const PageWaterFlow = lazy(() =>
  import('./myPages/SmartToilet/WaterFlow/WaterFlow_app')
);
const PageWaterLevel = lazy(() =>
  import('./myPages/SmartToilet/WaterLevel/WaterLevel_app')
);
const PageTrashBin = lazy(() =>
  import('./myPages/SmartToilet/TrashBin/TrashBin_app')
);
const PageWhatsapp = lazy(() => import('./myPages/Whatsapp_page/Whatsapp_app'));
const PageWaterLog = lazy(() => import('./myPages/ActivityLog_water/Log_app'));
const PageCubicleLog = lazy(() =>
  import('./myPages/ActivityLog_cubicle/Log_app')
);
const PageSoapLog = lazy(() => import('./myPages/ActivityLog_soap/Log_app'));
const PageBinLog = lazy(() => import('./myPages/ActivityLog_trashBin/Log_app'));
const PageHandLog = lazy(() => import('./myPages/ActivityLog_hand/Log_app'));
const PageTableView = lazy(() => import('./myPages/TableView/tv_app'));
const PageReport = lazy(() => import('./myPages/TableView/tv_app')); //import('./myPages/test/report'));
const PageDeveloping = lazy(() => import('./myPages/developing/develop_page'));
const PageNotSupport = lazy(() =>
  import('./myPages/not_support/noSupport_page')
);
const PageEntrance = lazy(() =>
  import('./myPages/entrance_overview/entrance_app')
);
const PageEntrance2 = lazy(() => import('./myPages/Entrance/entrance_app'));
const PageEntranceTplus = lazy(() =>
  import('./myPages/Tplus_Entrance/entrance_app')
);
const PageTesting = lazy(() => import('./myPages/SmartToilet/IAQ/liveBoard2'));
const PageIAQReport = lazy(() =>
  import('./myPages/SmartToilet/IAQ/IAQ_report')
);
const PageTeleport = lazy(() => import('./myPages/Teleport/entrance_app'));
const PageFloorOrder = lazy(() =>
  import('./myPages/FloorOrder/floorOrder_app')
);
const PageMapView = lazy(() => import('./myPages/Map/map'));
const PageFloorPlan = lazy(() => import('./myPages/FloorPlan/floorPlan_app'));
const PagePiDetail = lazy(() => import('./myPages/Pi_detail/Pi_app'));
const PageLiteHistory = lazy(() => import('./myPages/ViewLiteHistory/tv_app'));
const Routes = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  function teleport() {
    return (
      <Switch>
        <Redirect to="/PageTeleport" />
      </Switch>
    );
  }

  function not_support() {
    return (
      <Switch>
        <Redirect to="/PageNotSupport" />
      </Switch>
    );
  }

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            {t('loading')}
          </div>
        </div>
      </>
    );
  };

  //for translation
  const selected = localStorage.getItem('i18nextLng') || 'en';
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Redirect exact from="/" to="/PageLogin" />
          <Route path={['/PageLogin']}>
            <PresentationLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageLogin" component={PageLoginBasic} />
                </motion.div>
              </Switch>
            </PresentationLayout>
          </Route>

          {/* <StickyContainer>
              <Tabs defaultActiveKey="1" type="card" size='large' renderTabBar={renderTabBar} >
              <TabPane tab="Card Tab 1" key="1"> */}
          <Route
            path={[
              '/PageHome',
              '/PageWaterLeakage',
              '/PageIAQ',
              '/PageCubicle',
              '/PageSmoke',
              '/PageSoap',
              '/PageHandPaper',
              '/PageQueue',
              '/PagePeopleCount',
              '/PageDoor',
              '/PageTissuePaper',
              '/PageUrinal',
              '/PageWaterFlow',
              '/PageWaterLevel',
              '/PageTrashBin',
              '/PageTesting',
              '/PageTesting2',
              '/PageTesting3',
              '/PageWhatsapp',
              '/PageWaterLog',
              '/PageCubicleLog',
              '/PageSoapLog',
              '/PageHandLog',
              '/PageBinLog',
              '/PageTableView',
              '/PageMapView',
              '/PageReport',
              '/PageDeveloping',
              '/PageNotSupport',
              '/PageTesting',
              '/PageIAQReport',
              '/PageFloorOrder',
              '/PageFloorPlan',
              '/PagePiDetail',
              '/PageLiteHistory'
            ]}>
            <Layout className="layout" style={{ color: 'black' }}>
              <Header
                style={{
                  background:
                    'linear-gradient(8deg, rgba(41,171,226,1) 37%, rgba(140,248,158,1) 100%)',
                  position: 'fixed',
                  zIndex: 1116,
                  width: '100%'
                }}>
                {/* <a href="/PageHome#/AVANIoT/PageHome">
                  <div className="logo">
                    <img
                      src={avanLogo}
                      style={{
                        height: '60px',
                        float: 'left',
                        paddingRight: '120px'
                      }}
                      // style={{ float: 'right', margin: '16px 0 16px 24px'}}
                    />
                  </div>
                </a> */}
                <div
                  style={{
                    height: '60px',
                    float: 'left',
                    paddingRight: '120px'
                  }}></div>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={['2']}
                  style={{ backgroundColor: 'inherit' }}>
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      not_support();
                      console.log('clicked');
                    }}>
                    <a href="#/AVANIoT/PageNotSupport">{t('iot_sensor')} </a>
                  </Menu.Item>

                  <Menu.Item
                    key="2"
                    onClick={() => {
                      not_support();
                      console.log('clicked');
                    }}>
                    <a href="#/AVANIoT/PageHome">{t('Smart_Toilet')}</a>
                  </Menu.Item>

                  <Menu.Item
                    key="3"
                    onClick={() => {
                      not_support();
                      console.log('clicked');
                    }}>
                    {/* <a href="#/AVANIoT/PageTeleport">IoT Lighting </a> */}
                    <a href="#/AVANIoT/PageNotSupport">{t('iot_lighting')} </a>
                  </Menu.Item>

                  <Menu.Item
                    key="4"
                    onClick={() => {
                      not_support();
                      console.log('clicked');
                    }}>
                    {' '}
                    <a href="#/AVANIoT/PageNotSupport">
                      {t('security_system')}
                    </a>
                  </Menu.Item>

                  <Menu.Item
                    key="5"
                    onClick={() => {
                      not_support();
                      console.log('clicked');
                    }}>
                    <a href="#/AVANIoT/PageNotSupport"> {t('carpark')} </a>
                  </Menu.Item>
                </Menu>
                <Hidden smDown>
                  <a href="/PageHome#/AVANIoT/PageHome">
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                      <img
                        src={avanLogo}
                        style={{
                          height: '60px',
                          float: 'Right',
                          paddingLeft: '120px'
                        }}
                      />
                    </div>
                  </a>
                </Hidden>
              </Header>
            </Layout>

            <LeftSidebar>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageHome" component={PageHome} />
                  <Route
                    path="/PageWaterLeakage"
                    component={PageWaterLeakage}
                  />
                  <Route path="/PageIAQ" component={PageIAQ} />
                  <Route path="/PageCubicle" component={PageCubicle} />
                  <Route path="/PageSmoke" component={PageSmoke} />
                  <Route path="/PageSoap" component={PageSoap} />
                  <Route path="/PageHandPaper" component={PageHandPaper} />
                  <Route path="/PageMapView" component={PageMapView} />
                  <Route path="/PageQueue" component={PageQueue} />
                  <Route path="/PagePeopleCount" component={PagePeopleCount} />
                  <Route path="/PageDoor" component={PageDoor} />
                  <Route path="/PageTissuePaper" component={PageTissuePaper} />
                  <Route path="/PageUrinal" component={PageUrinal} />
                  <Route path="/PageWaterFlow" component={PageWaterFlow} />
                  <Route path="/PageWaterLevel" component={PageWaterLevel} />
                  <Route path="/PageTrashBin" component={PageTrashBin} />
                  <Route path="/PageWhatsapp" component={PageWhatsapp} />
                  <Route path="/PageWaterLog" component={PageWaterLog} /> 
                  <Route path="/PageBinLog" component={PageBinLog} />
                  <Route path="/PageSoapLog" component={PageSoapLog} />
                  <Route path="/PageCubicleLog" component={PageCubicleLog} />
                  <Route path="/PageHandLog" component={PageHandLog} />
                  <Route path="/PageTableView" component={PageTableView} />
                  <Route path="/PageReport" component={PageReport} />
                  <Route path="/PageDeveloping" component={PageDeveloping} />
                  <Route path="/PageNotSupport" component={PageNotSupport} />
                  <Route path="/PageEntrance" component={PageEntrance} />
                  <Route path="/PageTesting" component={PageTesting} />
                  <Route path="/PageIAQReport" component={PageIAQReport} />
                  <Route path="/PageTeleport" component={PageTeleport} />
                  <Route path="/PageFloorOrder" component={PageFloorOrder} />
                  <Route path="/PageFloorPlan" component={PageFloorPlan} />
                  <Route path="/PagePiDetail" component={PagePiDetail} />
                  <Route path="/PageLiteHistory" component={PageLiteHistory} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>

          {/* </TabPane>
            <TabPane tab="Card Tab 2" key="2"></TabPane>
            <TabPane tab="Card Tab 3" key="3"></TabPane>
            </Tabs>
            </StickyContainer> */}
          <Route path={['/PageLogin']}>
            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageLogin" component={PageLoginBasic} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>

          <Route
            path={['/PageEntrance', '/PageEntrance2', '/PageEntranceTplus']}>
            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageEntrance" component={PageEntrance} />
                  <Route path="/PageEntrance2" component={PageEntrance2} />
                  <Route
                    path="/PageEntranceTplus"
                    component={PageEntranceTplus}
                  />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>

          {/* 1 route 1 system */}
          <Route path={['/PageTeleport']}>
            <Layout className="layout">
              <Header
                style={{
                  backgroundColor: 'black',
                  position: 'fixed',
                  zIndex: 100000,
                  width: '100%'
                }}>
                <a href="/PageHome#/AVANIoT/PageHome">
                  <div className="logo">
                    <img
                      src={avanLogo}
                      style={{
                        height: '60px',
                        float: 'left',
                        paddingRight: '120px'
                      }}
                      // style={{ float: 'right', margin: '16px 0 16px 24px'}}
                    />
                  </div>
                </a>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={['3']}
                  style={{ backgroundColor: 'black' }}>
                  <Menu.Item key="1">IoT Sensor</Menu.Item>

                  <Menu.Item key="2">
                    <a href="#/AVANIoT/PageHome">Smart Toilet</a>
                  </Menu.Item>

                  <Menu.Item key="3">
                    <a href="#/AVANIoT/PageTeleport">IoT Lighting </a>
                  </Menu.Item>

                  <Menu.Item
                    key="4"
                    onClick={() => {
                      teleport();
                      console.log('clicked');
                    }}>
                    {' '}
                    Security System
                  </Menu.Item>

                  <Menu.Item key="5">Carpark System</Menu.Item>
                </Menu>
                {/* <div style={{ position: 'absolute', top: 0, right: 0, }}>
                    <img
                      src={avanLogoRight}
                      style={{ maxHeight: '60px', maxWidth: '150px' }}
                    />
                  </div> */}
              </Header>
            </Layout>

            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageTeleport" component={PageTeleport} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default withRouter(Routes);
