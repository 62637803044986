import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import AuthService from '../../services/auth.service';
import { BackTop, Popconfirm } from 'antd';
import { useHistory } from "react-router-dom";

import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import 'antd/dist/antd.css';
//import './index.css';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import OnlyPlayPauseButton from "../../myPages/howler/players/OnlyPlayPauseButton"

import { DownOutlined } from '@ant-design/icons';
import { Language } from '@styled-icons/ionicons-solid/Language'
//import avanlogo from './AVANIOT_BMS_IOT_SOLUTIONS.png'
////hidden
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },     //ltr: left to right
  tc: { label: "繁體中文", dir: "ltr", active: false },   //rtl: right to left,  有d人向左/向右睇起 
  sc: { label: "简体中文", dir: "ltr", active: false }
};

///////pop-up form


const LanguageSelect = () => {

  const selected = localStorage.getItem("i18nextLng") || "en";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const logOut = () => {
    AuthService.logout();
    history.push('/PageLogin');
    window.location.reload();
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const showPopconfirm = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
      <OnlyPlayPauseButton />
        <Hidden mdDown>
          <span className="d-inline-block pr-2">

            <Button style={{ backgroundColor: 'inherit', width: '150px' }} onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
              <Language size='20' />{languageMap[selected].label}
              <ArrowDropDown fontSize="small" />
            </Button>

            <Popover
              open={!!menuAnchor}
              anchorEl={menuAnchor}
              onClose={() => setMenuAnchor(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
            >
              <div>
                <List>
                  <ListSubheader>{t("select_language")}</ListSubheader>
                  {Object.keys(languageMap)?.map(item => (
                    <ListItem
                      button
                      key={item}
                      onClick={() => {
                        i18next.changeLanguage(item);
                        setMenuAnchor(null);
                        refreshPage();
                      }}
                    >
                      {languageMap[item].label}
                    </ListItem>
                  ))}
                </List>
              </div>
            </Popover>
          </span>
        </Hidden>
        {<span className="d-inline-block pr-2">
          {/* <div className="card-footer bg-white p-3 text-center d-block"> */}


          {/* <Hidden smDown> */}
            <div>
              <Popconfirm
                placement="bottom"
                title={t('logout_popup_ask')}
                okText={t('logout_popup_yes')}
                cancelText={t('logout_popup_no')}

                onConfirm={logOut}

                onCancel={handleCancel}
              //style={{ marginTop: '200px' }}
              >
                <Button
                  style={{ backgroundColor: 'inherit', width: '120px' }}


                // className="ml-1"
                //onClick={logOut}

                >
                  <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                  <span>{t("logout_button")}</span>
                </Button>
              </Popconfirm>

              <BackTop />
            </div>

          {/* </Hidden> */}
        </span>}
        {/* <span className="d-inline-block pr-2">
          <img src={avanlogo} style={{ width: '100px' }} />
        </span> */}
      </div>

    </>
  );
};

export default LanguageSelect;