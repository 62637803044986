import axios from "axios";

// const url = "http://localhost:443/";
const hostname = window.location.hostname;
const url = 'https://' + hostname + ':443/';

class AuthService {
  login(emp_mail, emp_password) {
    return axios
      .post(url + "login", {
        emp_mail,
        emp_password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();