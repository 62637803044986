import React, { useState, useEffect, useRef, createRef } from "react";
import ReactHowler from 'react-howler'
//import icon1 from './water_leakage.png';
import icon1 from './arlarm_oof.png';
//import icon2 from './water_leakage_shine.gif';
import icon2 from './alarm_onn.gif';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { VolumeMute } from '@styled-icons/boxicons-regular/VolumeMute'
import Timer from 'react-compound-timer'
import { useTranslation } from "react-i18next";
import { Space, Row, Col } from "antd";
import Hidden from '@material-ui/core/Hidden';
import { Button } from 'reactstrap';

const countdown = 30 * 60 * 1000;
const tiRef = createRef();  //add this so i can reset timer
const languageMap = {
  en: { label: "English", dir: "ltr", active: true },     //ltr: left to right
  tc: { label: "繁體中文", dir: "ltr", active: false },   //rtl: right to left,  有d人向左/向右睇起 
  sc: { label: "简体中文", dir: "ltr", active: false }
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // 儲存新回撥
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function OnlyPlayPauseButton() {
  const [visible, setVisible] = useState(false);
  const selected = localStorage.getItem("i18nextLng") || "en";
  const { t } = useTranslation();

  const hostname = window.location.hostname;
  const [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  const [trigger, setTrigger] = useState(false);
  const [pause, setPause] = useState(false);
  const [alarmIcon, setIcon] = useState(icon1);
  const [buttonIcon, setButton] = useState(button1)

  var button1 = <VolumeMute color='black' size='24' />

  function getMonitor() {
    const url = 'https://' + hostname + ':443/api/getWaterSensors';

    axios.get(url)
      .then(results => {
        var count = results.data;
        if (count > 0) {
          if (pause == true) {
            setTrigger(false);
            setIcon(icon2);

          } else {
            setTrigger(true);
            setIcon(icon2);
          }

        } else {
          setTrigger(false);
          setIcon(icon1);
        }

      }).catch(error => { console.log('ERR: ' + error); });
  };

  useEffect(() => {
    getMonitor();
  }, []);

  useInterval(() => {
    getMonitor();
  }, 1 * 1000);

  return (
    <>

      <div>
        <Row gutter={16} justify="center" align="middle">
          <Col span={4}>
            <ReactHowler
              src={['alarm.mp3']}
              playing={trigger}
              loop={true}
            />
            <a href="#/AVANIoT/PageWaterLeakage">
              <img style={{ width: 55, height: 30, margin: 'auto', marginRight: '25px' }} src={alarmIcon} alt="fireSpot" />
            </a>

          </Col>

          <Col span={20}>
            <Timer
              initialTime={countdown}
              direction="backward"
              ref={tiRef}
              startImmediately={false}
              checkpoints={[
                {
                  time: 30 * 60 * 1000 - 50,
                  callback: () => setPause(true),
                },
                {
                  time: 100,
                  callback: () => setPause(false),

                },
                {
                  time: 0,
                  callback: function (e) { tiRef.current.reset() },
                }
              ]}
            >
              {({ start, getTime }) => (
                <React.Fragment>
                  {/* <Hidden xsDown> */}
                    <Row gutter={16} justify="center" align="middle">

                      <br />
                      <Col span={24} style={{ margin: 'auto' }}>
                        <div style={{ textAlign: 'center' }}>

                          <Button onClick={start} style={{ outline: 'none', backgroundColor: 'inherit', textAlign: 'center', maxwidth: '170px' }}>


                            <VolumeMute size='24' />
                            <Timer.Minutes />{t('timer_min')} &nbsp;
                            <Timer.Seconds />{t('timer_sec')}


                          </Button>

                        </div>

                        <div style={{ color: '#000000', fontWeight: 'bold', textAlign: 'center' }}>

                        </div>
                      </Col>
                    </Row>
                  {/* </Hidden> */}
                </React.Fragment>
              )}
            </Timer>
          </Col>
        </Row>
      </div>
    </>
  );
}