import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import 'antd/dist/antd.css';
/////login issue
import axios from 'axios';
import authService from 'services/auth.service';

import { ProjectOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Water as WaterLevel } from '@styled-icons/boxicons-regular/Water';
import { Water as WaterFlow } from '@styled-icons/bootstrap/Water';
import { Man } from '@styled-icons/entypo/Man';
import { ToiletPaper } from '@styled-icons/fa-solid/ToiletPaper';
import { DoorOpen } from '@styled-icons/remix-line/DoorOpen';
import { PeopleOutline } from '@styled-icons/material/PeopleOutline';
import { PeopleTeam } from '@styled-icons/fluentui-system-regular/PeopleTeam';
import { MaleFemale } from '@styled-icons/foundation/MaleFemale';
import { HandPaper } from '@styled-icons/fa-regular/HandPaper';
import { PumpSoap } from '@styled-icons/fa-solid/PumpSoap';
import { Air } from '@styled-icons/material-rounded/Air';
import { Toilet } from '@styled-icons/fa-solid/Toilet';
import { Water } from '@styled-icons/entypo/Water';
import { Sensors } from '@styled-icons/material-sharp/Sensors';
import { Settings2Outline } from '@styled-icons/evaicons-outline/Settings2Outline';
import { Activity } from '@styled-icons/evaicons-solid/Activity';
import { CommentDetail } from '@styled-icons/boxicons-regular/CommentDetail';
import { LogoWhatsapp } from '@styled-icons/ionicons-solid/LogoWhatsapp';
import { SmokeFree } from '@styled-icons/material/SmokeFree';
import clsx from 'clsx';
import { Collapse } from 'reactstrap';
import { ChevronRight, Home, Monitor, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import OnlyPlayPauseButton from '../../myPages/howler/players/OnlyPlayPauseButton';
import { Badge, Button, Space } from 'antd';
import { tSParenthesizedType } from '@babel/types';
import { RaspberryPi } from '@styled-icons/fa-brands/RaspberryPi';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp';
///jenny icon
import logo_TrashBin from '../../myPages/Home/sensor_icon/sensor_icon/bin_icon.svg';
import logo_cubicle from '../../myPages/Home/sensor_icon/sensor_icon/sidebar_cubicle.svg';
import logo_waterLeakage from '../../myPages/Home/sensor_icon/sensor_icon/sidebar_waterleakage.svg';
import logo_soap from '../../myPages/Home/sensor_icon/sensor_icon/Untitled-1-05.png';
import logo_handPaper from '../../myPages/Home/sensor_icon/sensor_icon/Untitled-1-04.png';
import logo_iaq from '../../myPages/Home/sensor_icon/sensor_icon/Untitled-1-06.png';
import logo_door from '../../myPages/Home/sensor_icon/sensor_icon/door_icon.svg';
import logo_occupancy from '../../myPages/Home/sensor_icon/sensor_icon/occupancy_icon.svg';
import logo_queue from '../../myPages/Home/sensor_icon/sensor_icon/queue_icon.svg';
import logo_toiletpaper from '../../myPages/Home/sensor_icon/sensor_icon/toilet_paper_icon.svg';
import logo_urinal from '../../myPages/Home/sensor_icon/sensor_icon/sidebar_urinal.svg';
import logo_waterflow from '../../myPages/Home/sensor_icon/sensor_icon/waterflow_icon.svg';
import logo_waterlevel from '../../myPages/Home/sensor_icon/sensor_icon/waterlevel_icon.svg';
import { Bin } from '@styled-icons/icomoon/Bin';
const languageMap = {
  en: { label: 'English', dir: 'ltr', active: true }, //ltr: left to right
  tc: { label: '繁體中文', dir: 'ltr', active: false }, //rtl: right to left,  有d人向左/向右睇起
  sc: { label: '簡體中文', dir: 'ltr', active: false }
};

const SidebarMenu = (props) => {
  //const currentUser = authService.getCurrentUser();
  const hostname = window.location.hostname;
  // const [pageAccessible, setPageAccessible] = useState({});
  // React.useEffect(() => {
  //   let preparedData = { accessToken: currentUser["accessToken"] };
  //   const url = 'https://' + hostname + ':443/users/checkPermit';
  //   axios.post(url, preparedData)
  //     .then(result => {
  //       console.log(result);
  //       setPageAccessible(result.data);
  //     }).catch(error => {
  //       console.log('ERR: ' + error + " : " + error.response.data);
  //     });
  // }, []);

  const selected = localStorage.getItem('i18nextLng') || 'en';
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  const { setSidebarToggleMobile, sidebarUserbox } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [dashboardOpen, setDashboardOpen] = useState(true);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    event.preventDefault();
  };

  //////////////////////////////////////////////////////////////////
  const [elementsOpen, setElementsOpen] = useState(false);
  const toggleElements = (event) => {
    setElementsOpen(!elementsOpen);
    event.preventDefault();
  };

  const [pagesOpen, setPagesOpen] = useState(false);
  const togglePages = (event) => {
    setPagesOpen(!pagesOpen);
    event.preventDefault();
  };

  const [otherPagesOpen, setOtherPagesOpen] = useState(false);
  const toggleOtherPages = (event) => {
    setOtherPagesOpen(!otherPagesOpen);
    event.preventDefault();
  };

  const [applicationOpen, setApplicationOpen] = useState(false);
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen);
    event.preventDefault();
  };

  const [designSystemOpen, setDesignSystemOpen] = useState(false);
  const toggleDesignSystem = (event) => {
    setDesignSystemOpen(!designSystemOpen);
    event.preventDefault();
  };

  const [blocksOpen, setBlocksOpen] = useState(false);
  const toggleBlocks = (event) => {
    setBlocksOpen(!blocksOpen);
    event.preventDefault();
  };

  const [levelsOpen, setLevelsOpen] = useState(false);
  const toggleLevels = (event) => {
    setLevelsOpen(!levelsOpen);
    event.preventDefault();
  };

  const [widgetsOpen, setWidgetsOpen] = useState(false);
  const toggleWidgets = (event) => {
    setWidgetsOpen(!widgetsOpen);
    event.preventDefault();
  };

  const [chartsOpen, setChartsOpen] = useState(false);
  const toggleCharts = (event) => {
    setChartsOpen(!chartsOpen);
    event.preventDefault();
  };

  const [uiKitComponentsOpen, setUiKitComponents] = useState(false);
  const toggleUiKitComponents = (event) => {
    setUiKitComponents(!uiKitComponentsOpen);
    event.preventDefault();
  };

  const [formsComponentsOpen, setFormsComponents] = useState(false);
  const toggleFormsComponents = (event) => {
    setFormsComponents(!formsComponentsOpen);
    event.preventDefault();
  };

  const [collapsedLayoutOpen, setCollapsedLayoutOpen] = useState(false);
  const toggleCollapsedLayout = (event) => {
    setCollapsedLayoutOpen(!collapsedLayoutOpen);
    event.preventDefault();
  };

  const [pagesLoginOpen, setPagesLoginOpen] = useState(false);
  const togglePagesLogin = (event) => {
    setPagesLoginOpen(!pagesLoginOpen);
    event.preventDefault();
  };

  const [pagesRegisterOpen, setPagesRegisterOpen] = useState(false);
  const togglePagesRegister = (event) => {
    setPagesRegisterOpen(!pagesRegisterOpen);
    event.preventDefault();
  };

  const [pagesRecoverOpen, setPagesRecoverOpen] = useState(false);
  const togglePagesRecover = (event) => {
    setPagesRecoverOpen(!pagesRecoverOpen);
    event.preventDefault();
  };

  //Sensor Status for side-bar badge dot
  const water_warning = false;
  const door_warning = false;
  const soap_warning = false;
  const hpaper_warning = false;
  const iaq_warning = false;
  const smart_warning = false;

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          {/* <div className="sidebar-header">
            <span style={{color:'white'}}>{t("sidebar_title")}</span>
          </div> */}

          <ul>
            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/PageHome">
                <span className="sidebar-icon">
                  <Home />
                </span>
                {t('sidebar_home')}
              </NavLink>
            </li>

            <li>
              <a
                //href="/WaterLeakage#/WaterLeakage/PageNotSupport" //demo version
                href="#/"
                onClick={toggleElements}
                className={clsx({ active: elementsOpen })}>
                <span className="sidebar-icon">
                  <Settings2Outline />
                </span>
                <span className="sidebar-item-label">{t('sidebar_setup')}</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={elementsOpen}>
                <ul>
                  {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/PageWaterLeakage">
                      {t('sidebar_main_gate')}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageDeveloping">
                      {t('sidebar_end_point')}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageDeveloping">
                      {t('sidebar_pair_up')}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageDeveloping">
                      {t('sidebar_map')}
                    </NavLink>
                  </li> */}

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageWhatsapp">
                      {/* <LogoWhatsapp size="24"/> */}
                      <span className="sidebar-icon">
                        <Whatsapp />
                      </span>
                      {t('sidebar_whatsapp_alert')}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageFloorOrder">
                      {/* <LogoWhatsapp size="24"/> */}
                      <span className="sidebar-icon">
                        <Layer />
                      </span>
                      {t('sidebar_floor_order')}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PagepiDetail">
                      <span className="sidebar-icon">
                        <RaspberryPi />
                      </span>
                      {t('sidebar_pi')}
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleDashboard}
                className={clsx({ active: dashboardOpen })}>
                <span className="sidebar-icon">
                  <Sensors />
                </span>
                <span className="sidebar-item-label">
                  {t('sidebar_device')}
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={dashboardOpen}>
                <ul>
                  <li>
                    <a
                      href="#/"
                      onClick={toggleLevels}
                      className={clsx({ active: levelsOpen })}>
                      <span className="sidebar-icon">
                        <MaleFemale size="24" />
                      </span>
                      {t('sidebar_smart_toilet')}
                      <span className="sidebar-icon-indicator">
                        <ChevronRight />
                      </span>
                    </a>
                    <Collapse isOpen={levelsOpen}>
                      <ul>
                        {(() => {
                          if (water_warning == true) {
                            return (
                              <li>
                                <Badge dot offset={[-125, 10]}>
                                  <NavLink
                                    onClick={toggleSidebarMobile}
                                    to="/PageWaterLeakage">
                                    <Space size={[13, 24]}>
                                      <span className="sidebar-icon">
                                        <img src={logo_waterLeakage} />
                                      </span>
                                      {t('sidebar_waterleakage')}
                                    </Space>
                                  </NavLink>
                                </Badge>
                              </li>
                            );
                          } else if (water_warning == false) {
                            return (
                              <li>
                                <NavLink
                                  onClick={toggleSidebarMobile}
                                  to="/PageWaterLeakage">
                                  <span className="sidebar-icon">
                                    <img src={logo_waterLeakage} />
                                  </span>
                                  {t('sidebar_waterleakage')}
                                </NavLink>
                              </li>
                            );
                          }
                        })()}

                        {(() => {
                          if (door_warning == true) {
                            return (
                              <li>
                                <Badge dot offset={[-125, 10]}>
                                  <NavLink
                                    onClick={toggleSidebarMobile}
                                    to="/PageCubicle">
                                    <Space size={[13, 24]}>
                                      <span className="sidebar-icon">
                                        <img src={logo_cubicle} />
                                      </span>
                                      {t('home_card_cubicle')}
                                    </Space>
                                  </NavLink>
                                </Badge>
                              </li>
                            );
                          } else if (door_warning == false) {
                            return (
                              <li>
                                <NavLink
                                  onClick={toggleSidebarMobile}
                                  to="/PageCubicle">
                                  <span className="sidebar-icon">
                                    <img src={logo_cubicle} />
                                    {/* <img src={logo_cubicle} ></img> */}
                                  </span>
                                  {t('home_card_cubicle')}
                                </NavLink>
                              </li>
                            );
                          }
                        })()}

                        <li>
                          <NavLink
                            onClick={toggleSidebarMobile}
                            to="/PageTrashBin">
                            <span className="sidebar-icon">
                              {/* <img src={logo_cubicle} ></img> */}
                              <img src={logo_TrashBin} />
                            </span>
                            {t('home_card_trashBin')}
                          </NavLink>
                        </li>

                        {(() => {
                          if (iaq_warning == true) {
                            return (
                              <li>
                                <Badge dot offset={[-125, 10]}>
                                  <NavLink
                                    onClick={toggleSidebarMobile}
                                    to="/PageIAQ">
                                    <Space size={[13, 24]}>
                                      <span className="sidebar-icon">
                                        <Air size="24" />
                                      </span>
                                      {t('home_card_iaq')}
                                    </Space>
                                  </NavLink>
                                </Badge>
                              </li>
                            );
                          } else if (iaq_warning == false) {
                            return (
                              <li>
                                <NavLink
                                  onClick={toggleSidebarMobile}
                                  to="/PageIAQ">
                                  <span className="sidebar-icon">
                                    <Air size="24" />
                                  </span>
                                  {t('home_card_iaq')}
                                </NavLink>
                              </li>
                            );
                          }
                        })()}
                      </ul>
                    </Collapse>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li>
              <a
                href="#/"
                onClick={toggleWidgets}
                className={clsx({ active: widgetsOpen })}>
                <span className="sidebar-icon">
                  <Activity />
                </span>
                <span className="sidebar-item-label">
                  {t('sidebar_activity_log')}
                </span>
                <span className="sidebar-icon-indicator">
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={widgetsOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={toggleSidebarMobile}
                      className="nav-link-simple"
                      to="/PageWaterLog">
                      <span className="sidebar-icon">
                        <img src={logo_waterLeakage} />
                      </span>
                      {t('home_card_water_leakage')}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageBinLog">
                      <span className="sidebar-icon">
                        {/* <img src={logo_cubicle} ></img> */}
                        <img src={logo_TrashBin} />
                      </span>
                      {t('home_card_trashBin')}
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink
                      activeClassName="active"
                      onClick={toggleSidebarMobile}
                      className="nav-link-simple"
                      to="/PageNotSupport">
                      <span className="sidebar-icon">
                        <Bin />
                      </span>
                      {t('home_card_trashBin')}
                    </NavLink>
                  </li> */}
                </ul>
              </Collapse>
            </li>

            <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/PageTableView">
                <span className="sidebar-icon">
                  <CommentDetail />
                </span>
                {t('sidebar_detail')}
              </NavLink>
            </li>

            {/* <div>

              <OnlyPlayPauseButton />
            </div> */}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
