import React from 'react';
import Particles from "react-tsparticles";
import clsx from 'clsx';

import { connect } from 'react-redux';
import './logo.css'
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { Row, Col, Container } from 'reactstrap';
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderDrawer from '../../layout-components/HeaderDrawer';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderMenu from '../../layout-components/HeaderMenu';
import OverviewHeader from '../../layout-components/MyHeader/myheader';
//import background from "./head_bg.png";
import background from "./bg2.jpg";
import background2 from "./header-background.png"
import apple from './apple.png';
import { Layout, Menu, Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';
import avanLogo from '../../assets/images/myImage/AVAN.png';
/////////////////////////////
////antd header
const { Header, Content, Footer } = Layout;

//import background from 
const Headerr = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>


      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })} style={{ marginTop: '64px' }}>

        <div className="app-header--pane">

          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>

          {/* <HeaderSearch />
          <HeaderMenu /> */}
        </div>



        <div className="app-header--pane">
          <HeaderDots />
          <HeaderUserbox />
          {/* <HeaderDrawer /> */}
        </div>
      </div>


    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Headerr);
