import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';

import clsx from 'clsx';
import { Space, Tabs, notification, Badge, Button } from 'antd';
import { Layout, Menu, Breadcrumb } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  Collapse,
  TabContent,

  Nav,
  NavItem,

} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import projectLogo from '../../assets/images/react.svg';
import avanLogo from '../../assets/images/myImage/AVAN.png';
import apple from '../Header/apple.png';
import { NavLink } from 'react-router-dom';

///for tabs
const { TabPane } = Tabs;

/////////////////////////////
////antd header
const { Header, Content, Footer } = Layout;


function useInterval(callback, delay) {
  const savedCallback = useRef();

  // 儲存新回撥
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const sensor_location = "position A";
const sensor_name = "Sensor A"

const openNotification = placement => {
  notification.error({
    message: `Warning`,
    description:
      <div>
        location: {sensor_location}
        <br />
        Sensor: {sensor_name} is warning
      </div>,
    placement,
    duration: 0,
    type: 'error',
    style: {
      backgroundColor: '#fff1f0',
    },
  });
};


const lists = [
  { id: 1, title: "IoT Sensor" },
  { id: 2, title: "Smart Toilet" },
  { id: 3, title: "IoT Lighting" },
  { id: 4, title: "Security System" },
  { id: 5, title: "Carpark System" },
];

export default function LivePreviewExample() {
  const [activeTab, setActiveTab] = useState('1');


  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);


  
  const [selected, setSelected] = useState(0);
  const [state, setState] = useState({
    name: "bob",
    color: "blue"
  });

  const handleColor = (row) => {
    setSelected(row.id);
  };
  



  return (
    <>
     
      <div className="header-nav-wrapper header-nav-wrapper-lg navbar-dark" >
        <div  >
          <NavLink
            to="/PageHome"
            title="Linked Tech Smart Toilet"
            className="app-nav-logo app-nav-logo--light">
            <a href='/AVANIoT#/AVANIoT/PageHome'>
              <img src={avanLogo} style={{ height: '60px',  }} />
            </a>
{/* float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3); */}
          </NavLink>
        </div>

        <div style={{ margin: 'auto' }}>
          <div style={{ marginleft: '50%', marginTop: '8%' }}>
            <Space align='end'>


              <div>
                {lists.map((list) => (
                  <Button
                    key={list.id}
                    onClick={() => handleColor(list)}
                    style={{ backgroundColor: list.id === selected ? "#00aeef" : "", width: 'auto', color: "#FFFFFF", borderRadius: '0'}}
                    size='large' type="link" 
                  >
                    {list.title}
                  </Button>
                ))}
              </div>
              {/* <div>
                <Button onClick={nextColour} size='large' type="link" style={{
                  width: 'auto',
                  backgroundColor: colours[selectedColourIndex],


                  color: "#FFFFFF", borderRadius: '0'
                }} href='/AVANIoT#/AVANIoT/PageHome' >
                  IoT Sensors
                </Button>
              </div>

              <div>
                <Button onClick={() => { (state_system.system = 2); console.log(state_system.system) }} size='large' type="link" style={{ width: 'auto', background: 'inherit', color: "#FFFFFF", borderRadius: '0' }} href='/AVANIoT#/AVANIoT/PageHome' >
                  Smart Toilet
                </Button>
              </div>

              <div>
                <Button style={{ background: 'inherit', color: "#00aeef" }} href='/AVANIoT#/AVANIoT/PageDeveloping' >
                  IOT Lighting
                </Button>
              </div>

              <div>
                <Button style={{ background: 'inherit', color: "#00aeef" }} href='/AVANIoT#/AVANIoT/PageDeveloping' >
                  Security System
                </Button>
              </div>

              <div>
                <Button style={{ background: 'inherit', color: "#00aeef" }} href='/AVANIoT#/AVANIoT/PageDeveloping' >
                  Carpark System
                </Button>
              </div> */}
            </Space>
          </div>
        </div>
        <div  >
          <span style={{ fontSize: '30px', color: '#00aeef', fontWeight: 'bold' }}>AvanIoT </span>
          {/* <img src={avanLogo} style={{height:'60px'}}/> */}
          <div style={{ textAlign: 'center', color: 'whitesmoke', fontWeight: 'bold' }}> IoT Solutions</div>
        </div>
        <div className="app-nav-logo--text" style={{ marginRight: '1%' }}>
          Tel: +852 39969590 | Email: info@avanlite.com
          {/* <div style={{ textAlign: 'right' }}> Tel: +852 39969590 | Email: info@avanlite.com</div> */}
        </div>
      </div>

      <div
        className={clsx('collapse-page-trigger', { 'is-active': collapse })}
        onClick={toggle}
      />
    </>
  );



}
