import { createGlobalStyle } from "styled-components"


export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 1.0s linear;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }

  button {
    background: ${({ theme }) => theme.button};
    border-radius: 3px;
    border: none;
    color: white;
  }

  card {
    background: ${({ theme }) => theme.button};
    border-radius: 3px;
    border: none;
    color: white;
  }
  `



export const lightTheme = {
    body: '#FFF',
    text: '#000',
    toggleBorder: '#FFF',
    button: 'red'
}


export const darkTheme = {
    body: '#000',
    text: '#FFF',
    toggleBorder: '#6B8096',
    button: 'blue'
}